@import "variables";
@import "font";
@import "src/scss/custom";

// Nav Bar

.sidebar {
  width: $sidebar-width !important;
}

.app-body .sidebar-show ~ .main {
  margin-left: $sidebar-width !important;
}

.app-body .sidebar-hide ~ .main {
  margin-left: 0 !important;
}

.style-nav {
  background-color: $nav-background-color;
  padding: 2rem;
}

.nav-ul {
  padding: 0;
}

.nav-item {
  list-style: none;
  padding: 0.75rem 1rem;
  color: $nav-inactive-text-color;
  border-radius: 0.625rem;
  cursor: pointer;
  margin: 0.25rem 0.5rem;
}

.nav-item.active {
  background-color: $krugo-black;
  color: $krugo-white;
}

.nav-item.passive-selection {
  background-color: rgba(255, 255, 255, 0.1);
  color: $nav-active-text-color;
}

.nav-item.passive-selection .nav-item-label {
  height: 2.25rem;
  line-height: 2.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-item.passive-selection .nav-item-label.multi-line-label {
  line-height: 1.125rem;
}

.nav-item-label {
  font-size: 1rem;
  text-align: left;
  vertical-align: top;
  padding-left: 0.5rem;
  color: $krugo-black;
  @extend .default-regular;
}

li.nav-item.active .nav-item-label {
  color: $krugo-white;
  @extend .default-bold;
}

li.nav-item.active .nav-bar-icon {
  display: none;
}

li.nav-item.active .nav-bar-icon-filled {
  display: inline-block;
  opacity: 1;
}

.nav-bar-icon, .nav-bar-icon-filled {
  height: 100%;
  width: 1.5rem;
  display: inline-block;
  opacity: 0.7;
}

.nav-bar-icon-filled {
  display: none;
}

.nav-item:hover:not(.active) {
  background-color: $hover-gray-color;
}

.nav-item:hover .nav-bar-icon {
  opacity: 1;
}

.nav-item:hover .nav-item-label {
}

.nav-item-notification-pill {
  background-color: $nav-notification-pill-color;
  color: $white-color;
  display: inline-block;
  text-align: center;
  line-height: 0.625rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.375rem;
  vertical-align: middle;
  border-radius: 1.125rem;
  min-width: 1.5rem;
  float: right;
  margin-top: 0.2rem;
  @extend .default-semi-bold;
}

/* Text_Field_Start ----------------------------------------------- */
//noinspection ALL
.custom-textfield {
  color: $primary-text-color;
  height: 2.5rem;
  width: -webkit-fill-available;
  border: 0;
  font-size: 0.875rem;
  border-radius: 0;
  line-height: 1rem;
  text-align: left;
  border-bottom: 1px solid $krugo-light-grey;
  padding: 0.8125rem 0rem;
  @extend .default-regular;
}

.custom-textfield:read-only {
  outline: none;
  border-radius: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: $default-gray-color;
}

.custom-textfield:read-only:focus {
  outline: none;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: $default-gray-color;
}

.custom-textfield:focus {
  color: $primary-text-color;
  outline: none;
  border-radius: 0;
  border-bottom: 1px solid $krugo-black;
  -webkit-box-shadow: none;
  @extend .default-medium;
}


/* ------------------------------------------------- Text_Field_End */

// I named things according to how they were labelled in xd.adobe by Conor
/* Text_Start ----------------------------------------------- */
.custom-headline {
  @extend .default-bold;
  @extend .zero-letter-spacing;
  font-size: 2rem;
  color: $black-color;
  text-align: left;
  opacity: 1;
}

.custom-section-title {
  @extend .default-bold;
  @extend .zero-letter-spacing;
  font-size: 1.5rem;
  color: $black-color;
  text-align: left;
  opacity: 1;
}

.custom-section-subtitle {
  @extend .default-bold;
  @extend .zero-letter-spacing;
  color: $black-color;
  font-size: 1rem;
  text-align: left;
  opacity: 1;
}

.custom-section-subtext {
  @extend .default-regular;
  @extend .zero-letter-spacing;
  color: $extra-dark-gray-color;
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: left;
  opacity: 1;
}

.custom-modal-section-header-text {
  @extend .default-bold;
  @extend .zero-letter-spacing;
  color: $black-color;
  font-size: 1rem;
  text-align: left;
  opacity: 1;
}

.custom-error-text {
  @extend .default-regular;
  @extend .zero-letter-spacing;
  color: $red-color;
  font-size: 0.625rem;
  text-align: left;
  opacity: 1;
}

h3 {
  @extend .default-bold;
  @extend .zero-letter-spacing;
  color: $black-color;
  text-align: left;
  font-size: 1.125rem;
  opacity: 1;
}

body {
  @extend .default-regular;
  @extend .zero-letter-spacing;
  color: $black-color;
  text-align: left;
  font-size: 0.875rem;
  opacity: 1;
}

.main-title {
  @extend .default-bold;
  font-size: 2.3rem;
  margin-bottom: 2rem;
  line-height: normal;
}

.divider {
  background-color: $krugo-light-grey;
  height: 1px;
  width: 100%;
}

.divider.black {
  background-color: $krugo-black;
}

/* ------------------------------------------------- Text_End */

/* Buttons_Start -------------------------------------------- */
.custom-button {
  padding: 0.75rem 1rem;
  margin: 0;
  border: none;
  border-radius: 0.625rem;
  line-height: 1.125rem;
  opacity: 1;
  height: 2.75rem;
  font-size: 0.875rem;
  text-align: center;
  vertical-align: middle;
  transition: background 0.8s;
  cursor: pointer;
  @extend .zero-letter-spacing;
  @extend .default-semi-bold;
  img {
  margin-right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  }
}

.custom-button img {
  margin-right: 0.5rem;
  height: 100%;
}

.custom-button:focus {
  outline: none;
}

.custom-button .show-on-hover {
  display: none;
}

.custom-button:hover .hide-on-hover {
  display: none;
}

.custom-button:hover .show-on-hover {
  display: inline-block;
}

.custom-button:active {
  background-color: $light-grey-color;
  background-size: 100%;
  transition: background 0s;
}

.preferred-button {
  background-color: $krugo-yellow;
  color: $krugo-black;
}

.icon-button-icon {
  margin-right: 0.625rem;
  height: 100%;
}

.rounded-icon-button {
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  border-radius: 50%;
}

.rounded-icon-button .icon-button-icon {
  margin: 0;
}

.preferred-button:hover {
  background-color: $krugo-yellow;
}

.preferred-button.disabled {
  background-color: $krugo-light-grey;
}

.neutral-button {
  background-color: $krugo-black;
  color: $krugo-white;
}

.neutral-button:hover {
  background-color: $button-neutral-hover-color;
}

.neutral-button.disabled {
  background-color: $default-gray-color;
  opacity: 0.5;
}

.grey-button {
  background-color: $krugo-light-grey;
  color: $krugo-black;
}

.grey-button:hover {
  background-color: $krugo-grey;
}

.dark-neutral-button {
  background-color: $button-dark-neutral-color;
  color: $button-white-text-color;
}

.dark-neutral-button:hover {
  background-color: $button-dark-neutral-hover-color;
}

.dark-neutral-button.disabled {
  background-color: $default-gray-color;
}

.outlined-button {
  background-color: $white-color;
  color: $button-black-text-color;
  @extend .bordered-button
}

.outlined-button:hover {
  background-color: $button-outlined-hover-color;
}

.outlined-button.disabled {
  background-color: $light-grey-color;
  border: none;
}

.destructive-button {
  background-color: $white-color;
  color: $red-color;
  border: 0.124rem solid $red-color;
}

.destructive-button:hover {
  background-color: $red-color;
  color: $button-white-text-color;
}

.destructive-button.disabled {
  background-color: $light-grey-color;
  color: $button-black-text-color;
  border: none;
}

.text-button {
  background-color: $krugo-white;
  color: $krugo-dark-grey;
}


.back-button {
  padding: 0.75rem 1rem 0.75rem 0;
  background-color: $button-text-color;
  color: $button-black-text-color;
  opacity: 0.5;
}

.back-button img {
  margin-right: 0.25rem;
}

.back-button:hover {
  opacity: 1;
  background-color: $button-text-color;
}

.text-link-helper {
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: left;
  color: $primary-blue-color;
  @extend .default-medium
}

.inline-text-link {
  display: inline-block;
  margin: 0 0.5rem;
}

.text-link {
  color: $krugo-black !important;
  @extend .default-semi-bold
}

.text-link.disabled {
  color: $light-grey-color;
}

.text-link.disabled:hover {
  text-decoration: none;
  cursor: none;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-link.destructive-link {
  color: $red-color;
}

.text-link.destructive-link.disabled {
  color: $light-red-color;
}

.bordered-button {
  border: 0.124rem solid $button-border-color;
}

.full-width-button {
  width: 100%;
}

.in-button-loading {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 1.5rem;
  height: 100%;
}

.in-button-loading app-loading .loading-container {
  background-color: transparent !important;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .2s, opacity 0.5s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
  }
}

/* ---------------------------------------------- Buttons_End */


/* Labels_Start -------------------------------------------- */
.custom-label {
  padding: 0.125rem 0.4rem;
  border: none;
  border-radius: 0.3rem;
  line-height: 1.125rem;
  opacity: 1;
  height: 1.25rem;
  font-size: 0.625rem;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  @extend .default-semi-bold;
}

.preferred-label {
  background-color: $primary-blue-color;
  color: $white-color;
}

.positive-label {
  background-color: $label-positive-color;
  color: $label-positive-text-color;
}

.neutral-label {
  background-color: $label-neutral-color;
  color: $label-neutral-text-color;
}

.negative-label {
  background-color: $label-negative-color;
  color: $label-negative-text-color;
}

/* ---------------------------------------------- Labels_End */

/* Toggle_Switch_Start ------------------------------------- */
.custom-switch-container {
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: left;
  color: $primary-text-color;
  @extend .default-medium;

  img {
    height: 1rem;
    width: 1rem;
    margin-left: 1.5rem;
    opacity: 0.5;
  }

  label {
    margin-left: 1rem;
  }
}

/* The switch - the box around the slider */
.custom-switch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 2.875rem;
  height: 1.5rem;
  margin-left: 1.125rem;
}

/* Hide default HTML checkbox */
.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $switch-base-color;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.custom-slider:hover {
  background-color: $switch-hover-color
}

.custom-slider:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  top: 0.1rem;
  left: 0.15rem;
  background-color: $white-color;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .custom-slider {
  background-color: $switch-activated-color;
}

input:focus + .custom-slider {
  outline: none;
}

input:checked + .custom-slider:before {
  -webkit-transform: translateX(1.375rem);
  -ms-transform: translateX(1.375rem);
  transform: translateX(1.375rem);
}

/* --------------------------------------- Toggle_Switch_End */

/* Radio_Start --------------------------------------------- */
.custom-radio-form {
  vertical-align: middle;
}

.custom-radio-label {
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: left;
  margin-left: 0.375rem;
  vertical-align: middle;
  margin-bottom: 0;
  @extend .default-medium;
}

.custom-radio {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
}

.custom-radio-option {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.custom-radio-option-inline {
  display: inline-block;
  padding: 0 1rem;
}

.custom-radio-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-custom-radio {
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
  background-color: $radio-base-color;
  border-radius: 50%;
  flex-shrink: 0;
}

.custom-radio-option.disabled input {
  cursor: not-allowed;
}

.custom-radio-option.disabled:hover .custom-custom-radio {
  background: $radio-base-color;
}

.custom-radio-option:hover .custom-custom-radio {
  background-color: $radio-hover-color;
}

.custom-radio-option input:checked ~ .custom-custom-radio {
  background-color: $radio-activated-color;
}

.custom-custom-radio:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio-option input:checked ~ .custom-custom-radio:after {
  display: block;
}

.custom-radio-option .custom-custom-radio:after {
  position: relative;
  top: 0.625rem - (0.375rem / 2);
  left: 0.625rem - (0.375rem / 2);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: $white-color;
}

/* ----------------------------------------------- Radio_End */

/* Toggle_Start -------------------------------------------- */
.custom-toggle {
  border-radius: 2px;
  background-color: $toggle-base-color;
  height: 2rem;
  width: 2rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.5625rem 0;
  @extend .default-medium;
}

.custom-toggle:hover {
  background-color: $toggle-hover-color;
}

.custom-toggle.active {
  background-color: $toggle-activated-color;
  color: $white-color;
}

/* ---------------------------------------------- Toggle_End */


/* Cards_Start --------------------------------------------- */

.custom-card {
  width: 14rem;
  height: 10rem;
  min-width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem !important;
  opacity: 1;
  background-size: cover;
  background-position: top;
  cursor: pointer;
  @extend .custom-card-radius-top;
  @extend .custom-card-radius-bottom;
}

.card.custom-card:hover .custom-card-label {
  background-color: $hover-gray-color;
  opacity: 1.0;
  height: 5rem;
  transition: 0.25s ease-in-out;
}

.custom-card-label {
  height: 4.5rem;
  background: $card-banner-color 0 0 no-repeat padding-box;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: auto;
  @extend .custom-card-radius-bottom;
}

.custom-card-radius-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.custom-card-radius-bottom {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.custom-card-label-title {
  @extend .default-medium;
  @extend .zero-letter-spacing;
  text-align: left;
  font-size: 0.875rem;
  color: #737680;
  opacity: 1;
}

.custom-card-label-text {
  @extend .default-bold;
  @extend .zero-letter-spacing;
  font-size: 1.3rem;
  text-align: left;
  color: $card-text-color;
  opacity: 1;
}

.card-deck {
  margin: 1.5rem 0 0 0;
}

.custom-card-deck {
  margin: 1.5rem 0 0 0;
  overflow-y: hidden;
  max-height: 20rem;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 0.25rem;
}

.custom-card-deck .card {
  display: inline-flex !important;
}

.custom-card-with-bottom-margin {
  margin-bottom: 1rem !important;
}


/* ----------------------------------------------- Cards_End */


/* Media_Upload_Start -------------------------------------- */
.custom-upload-area {
  border: 1px dashed $media-upload-border;
  border-radius: 0.625rem;
  background-color: $media-upload-background;
  width: 100%;
  display: table;
}

.custom-upload-form {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.custom-upload-icon {
  height: 1.5rem;
  margin: 1.5rem auto auto;
  display: block;
}

.custom-upload-text {
  display: block;
  padding-top: 1rem;
  font-size: 0.875rem;
  line-height: 1rem;
  @extend .default-medium;
}

.custom-upload-label {
  color: $media-upload-blue-color;
}

.custom-upload-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.secondary-upload-text {
  padding-top: 0.5rem;
  color: $media-upload-grey-color;
  margin-bottom: 1.5rem;
}

.custom-file-input {
  display: none;
}

.custom-upload-list-container {
  width: 100%;
  padding: 1.25rem 0;
}

.custom-upload-list {
  list-style: none;
  display: inline-flex;
  padding: 0;
  margin-left: -0.75rem;
}

.modal-custom-upload-list {
  list-style: none;
  display: list-item;
  padding: 0;
  overflow-y: auto;
  max-height: 15rem;
  margin-left: -0.75rem;
}

.custom-upload-button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.custom-upload-button:focus {
  outline: none;
}

.custom-upload-button-icon {
  height: 1.25rem;
  background-color: transparent;
  filter: invert(49%) sepia(8%) saturate(428%) hue-rotate(189deg) brightness(92%) contrast(86%);
}

.custom-upload-preview.error .custom-remove-upload-button {
  display: none;
}

.custom-upload-preview.error .custom-refresh-upload-button {
  display: inline-block;
}

.custom-upload-preview.success .custom-remove-upload-button {
  display: inline-block;
}

.custom-upload-preview.success .custom-refresh-upload-button {
  display: none;
}

/* ---------------------------------------- Media_Upload_End */

/* Lists_Start --------------------------------------------- */
.custom-search-results {
  width: 100%;
  border-radius: 0.625rem;
  background-color: $list-background-color;
  border: 1px solid $list-border-color;
  margin: 0.625rem;
  max-height: 33vh;
}

.custom-results-container {
  overflow: scroll;
  max-height: 26vh;
  display: block;
}

.custom-search-results-header {
  padding: 1.5625rem 0 1rem 0;
  display: block;
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: left;
  border-bottom: 1px solid $default-gray-color;
  width: calc(100% - 4rem);
  margin: 0 2rem;
  @extend .default-medium;
}

.custom-list {
  list-style: none;
  padding: 0 1rem 0.5rem 1rem;
  margin: 0;
}

.custom-list-item-image {
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.75rem;
  border-radius: 0.25rem;
}

.custom-list-item-image-icon {
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.75rem;
  border-radius: 0.25rem;
  background-color: $default-gray-color;
  padding: 0.75rem;
}

.custom-list-item-text-container {
  padding: 0 1rem;
  display: inline-block;
  width: calc(100% - 4rem);
  vertical-align: middle;
}

.custom-list-item-text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  @extend .default-bold;
}

.custom-list-item-subtext {
  font-size: 0.625rem;
  line-height: 0.75rem;
  display: flex;
  @extend .default-medium;
}

.custom-list-item {
  padding: 0.75rem 0;
}

.custom-list-item:hover {
  background-color: $list-hover-color;
  cursor: pointer;
  border-radius: 0.25rem;
}

.custom-list-item.inactive:hover {
  background-color: $list-background-color;
}

.custom-list-item.active {
  background-color: $list-active-color;
  cursor: text !important;
}


.menu-list-container {
  max-height: 30rem;
  padding-top: 1rem;
  width: 100%;
  display: block;
  background: $white-color;
}

.menu-list-item {
  padding: 0.75rem 1rem;
  color: $primary-text-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  background: $white-color;
  z-index: 99;
  box-shadow: 0 0 2px 2px rgba(218, 220, 222, 0.7);
  border-radius: 0.625rem;
  margin: 1rem 0;
}

.menu-list-item.disabled {
  cursor: not-allowed;
  background-color: $extra-light-grey-color;
}

.menu-added {
  @extend .default-semi-bold;
}

/* ----------------------------------------------- Lists_End */

/* Image_Start --------------------------------------------- */
.custom-profile-image {
  display: inline-block;
  border-radius: 50%;
  height: 5.625rem; // 90px;
  width: 5.625rem; // 90px;
  background-color: $default-gray-color;
  cursor: pointer;
}

.custom-profile-image-padding {
  padding: 1.75rem;
}

/* ----------------------------------------------- Image_End */

/* Popper_Start --------------------------------------------- */

.custom-popper-content {
  border: none !important;
  border-radius: 0.625rem !important;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;
}

/* ----------------------------------------------- Popper_End */

/* Modal_Start --------------------------------------------- */

.sticky-header-container {
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.sticky-body-container {
  overflow-y: scroll;
  padding: 0 1.5rem;
}

.sticky-footer-container {
  background: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1), #FCFCFC, #FCFCFC);
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 0 1.5rem 0;
  z-index: 10;
}

.sticky-footer-container.modal-footer-flex-end {
  padding-top: 3rem;
}

.modal-form-negative-padding {
  margin: 0 -1.5rem;
}

.modal-body {
  max-height: calc(100vh - 3rem);
  overflow-y: auto;
  padding: 1.5rem;
}

.modal-body.sticky {
  padding: 0;
}

.modal-header {
  display: block;
  padding: 0 0 1.25rem 0;
  border-bottom: 1px solid $hover-gray-color;
}

.modal-title {
  display: block;
  font-size: 1.5rem;
  flex: 1;
  color: $primary-text-color;
  @extend .default-bold;
}

.modal-description {
  display: block;
  @include f14px;
  flex: 0 1 auto;
  overflow-y: scroll;
  color: $primary-text-color;
  @extend .default-regular;
}

.modal-subtitle {
  display: block;
  font-size: 1.125rem;
  line-height: 2rem;
  color: $primary-text-color;
  @extend .default-bold;
}

.modal-sub-subtitle {
  font-size: 0.875rem;
  text-align: left;
  color: $primary-text-color;
  @extend .default-medium;
}

.modal-sub-subtitle-text {
  display: inline-block;
  width: 50%;
  color: $extra-dark-gray-color;
  @extend .default-medium;
}

.modal-sub-subtitle-text span {
  display: inline-block;
  color: $primary-text-color;
  @extend .default-semi-bold;
}

.inline-subtitle {
  display: inline-block;
  min-width: 50%;
}

.modal-subtitle-action {
  float: right;
  top: -0.5rem;
  position: relative;
  text-align: right;
}

.footer-button {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 1rem 0;
  float: right;
}

.modal-footer-flex-end {
  display: flex;
  justify-content: flex-end;
}

.modal-divider {
  width: 100%;
  border-bottom: 1px solid $hover-gray-color;
  margin: 0.5rem 0;
}


.insight-info-modal {
  height: 32rem;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: $krugo-white;
}

.create-guide-modal {
  min-height: 80vh;
  max-height: 90vh;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: $krugo-white;
}

.rearrange-assets-modal {
  min-height: 20rem;
  max-height: 52rem;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: $krugo-white;
}

.reorder-action-container {
  max-height: 35rem;
}

.add-place-modal {
  min-height: 75vh;
  max-height: 95vh;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: $krugo-white;
}

.edit-guide-feature-modal {
  min-height: 45vh;
  max-height: 95vh;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: $krugo-white;
}

/* ----------------------------------------------- Modal_End */

/* Drop_Down_Menu_Start ------------------------------------ */
.custom-dropdown-menu {
  border-radius: 0.625rem;
  width: fit-content;
  overflow: visible;
  border-color: $hover-gray-color;
}

.custom-dropdown-menu-header {
  padding-top: 1rem;
  border-bottom: 1px solid $black-color;
  padding-bottom: 3px;
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  @extend .default-bold;
  @extend .custom-item-sizing;
}

.custom-dropdown-menu-item {
  border: none;
  width: 100%;
  min-width: 200px;
  max-width: 320px;
  background-color: $white-color;
  text-align: left;
  border-radius: 0.625rem;
  padding: 0.625rem 1rem;
  @extend .default-regular;
  @extend .custom-item-sizing;
}

.custom-item-sizing {
  min-width: 200px;
  max-width: 400px;
}

.custom-dropdown-menu-item:hover {
  background-color: $off-white-color;
}

.custom-dropdown-menu-item:focus {
  outline: none;
  background-color: $hover-gray-color;
}

.custom-dropdown-menu-remove {
  color: $red-color;
}

/* -------------------------------------- Drop_Down_Menu_End */

/* Drop_Down_Start ----------------------------------------- */
.custom-dropdown {
  padding: 0 0.75rem 0 0.875rem;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.625rem;
  background-color: $dropdown-base-color;
  color: $dropdown-text-color;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border: none;
  @extend .default-regular;
  // Hide the default chevron
  -webkit-appearance: none;
  -moz-appearance: none;
  // Add the custom chevron
  background-image: url(/src/assets/icons/light/drop-down.svg);
  background-position-x: calc(100% - 0.7rem);
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
}

.custom-dropdown:focus {
  outline: none;
}

.custom-dropdown:enabled:hover {
  background-color: $dropdown-hover-color;
  border: 1px solid $dropdown-hover-border-color;
  color: $dropdown-hover-text-color;
  @extend .default-regular;
}

.custom-dropdown:disabled, .custom-textfield:disabled, .custom-switch-input:disabled, .custom-slider.disabled {
  opacity: 0.5;
}

.custom-dropdown:disabled:hover, .custom-textfield:disabled:hover, .custom-switch-input:disabled:hover, .custom-slider.disabled:hover {
  cursor: not-allowed;
}

.custom-dropdown-label {
  font-size: 0.8rem;
  line-height: 1rem;
  color: $black-color;
  @extend .default-medium;
}

/* ------------------------------------------- Drop_Down_End */


/* No_Results_Start ----------------------------------------- */

.no-results-container {
  margin: 6rem auto auto auto;
  width: 40%;
  height: 18rem;
  display: block;
}

.no-results-title {
  font-size: 1.5rem;
  line-height: 3rem;
  color: $primary-text-color;
  @extend .default-bold;
}

.no-results-body {
  font-size: 1rem;
  line-height: 1.125rem;
  color: $extra-dark-gray-color;
  @extend .default-regular;
}

.no-results-button {
  margin: 1.5rem 0 0 0;
}

/* ------------------------------------------- No_Results_End */


/* Edit_Section_Start ----------------------------------------- */

.custom-edit-section-title {
  font-size: 1rem;
  color: $primary-text-color;
  @extend .default-semi-bold;
}

.custom-edit-section-description {
  font-size: 0.875rem;
  color: $extra-dark-gray-color;
  @extend .default-regular;
}

.custom-edit-section-button-container {
  display: block;
}

.custom-edit-section-button-container .custom-button {
  margin: 0 0.5rem 0 0;
}

.custom-section-container {
  position: relative;
  margin-top: 0.5rem;
}

.custom-section-header {
  display: block;
  width: 100%;
}

.custom-section-body {
  display: block;
  width: 100%;
}

.custom-section-header-button-container {
  display: inline-block;
  float: right;
}

.custom-section-list-title {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $primary-text-color;
  @extend .default-bold;
}

/* ------------------------------------------- Edit_Section_End */


/* Tooltip_Start ----------------------------------------- */

.inline-tooltip {
  height: 0.75rem;
  width: 0.75rem;
}

.inline-tooltip:focus {
  outline: none;
}

.datatable-action-button img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.inline-tooltip img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.inline-tooltip {
  outline: none;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0.25rem;
}

.inline-tooltip:hover img {
  opacity: 1;
}

/* ------------------------------------------- Tooltip_End */


/* Warning_Start ----------------------------------------- */

.warning-message {
  margin: 0.5rem 1.5rem;
  padding: 0.75rem 1rem;
  background-color: $yellow-color-80;
  border-radius: 0.5rem;
  text-align: left;
  color: $primary-text-color;
  @extend .default-semi-bold;
}

.warning-message .tooltip-container {
  float: right;
}

/* ------------------------------------------- Warning_End */


// Links
a {
  text-decoration: underline;
  color: $krugo-black;
  @extend .default-semi-bold;
}

// End_Links
